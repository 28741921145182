<template>
  <div style="background-color: #F6F9FC">
    <v-app style="background-color: #F6F9FC">

        <!--头部-->
        <base-app-bar />
        <!--中间内容-->
        <v-container class="py-6 mb-12  container">
        <v-row>
            <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}" @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar" :class="{'open': isSidebar}" style="background-color:#F6F9FC">
                <left-menu-user/>
                </div>
                <div class="box-content">
                <v-card class="shadow-sm mb-3 pa-1 d-block d-md-none" v-if="showBox">
                    <v-btn
                        icon
                        @click="isSidebar = !isSidebar" 
                    >
                    <v-icon dark>
                        mdi-format-list-bulleted-square
                    </v-icon>
                    </v-btn>
                    <span>{{$t('菜单')}}</span>
                </v-card>
                <router-view>
                    <!-- <v-card width="100%" height="400"> -->
                    <div class="loading-content">
                        <div class="loading-animate"/>
                    </div>
                    <!-- </v-card> -->
                </router-view>
                </div>
            </div>
            </v-col>
        </v-row>
        </v-container>
        <!--底部-->
        <footer-view />
        <mobile-navigation />
    </v-app>
    
  </div>
</template>
<script>

export default {
  components: {
    BaseAppBar: () => import('../../layouts/base/AppBar'),
    BaseView: () => import('../../layouts/base/View'),
    FooterView: () => import('../../layouts/base/Footer'),
    leftMenuUser: () => import('../../layouts/base/user-left-menu'),
    MobileNavigation: () => import('../../layouts/base/MobileNavigationBar'),
  },
  data() {
    return {
      transitionName: '',
      isMobile: false,
      isSidebar: false,
      showBox: true,
    };
  },
  computed: {},
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slide-left' : 'slide-right';
    },
  },
  created() {
    this.isMobile = this.$store.state.isMobile;
  },
  mounted() {

  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.v-main {
  padding: 0px 0px 0px !important;
}
</style>
